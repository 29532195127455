import { render, staticRenderFns } from "./TableStats.vue?vue&type=template&id=63b25899&scoped=true&"
import script from "./TableStats.vue?vue&type=script&lang=js&"
export * from "./TableStats.vue?vue&type=script&lang=js&"
import style0 from "./TableStats.vue?vue&type=style&index=0&id=63b25899&prod&lang=css&"
import style1 from "./TableStats.vue?vue&type=style&index=1&id=63b25899&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63b25899",
  null
  
)

export default component.exports