<template>
  <div>
    <b-table
      small
      :items="rows"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      foot-clone
      striped
    >
      <template #head(date)>
        {{ $t('message.tableHeader.date') }}
      </template>

      <template #head(impressions)>
        {{ $t('message.tableHeader.impressions') }}
      </template>

      <template #head(clicks)>
        {{ $t('message.tableHeader.clicks') }}
      </template>

      <template #head(revenue)>
        {{ $t('message.tableHeader.ecpm') }}
      </template>

      <template #cell(date)="row">
        <span v-if="row">
          <a
            href="#"
            :class="row.item._showDetails === true ? 'text-secondary' : ''"
            @click.prevent="showDetails(row)"
          >
            <strong>{{ getFormattedDate(new Date(row.item.date)) }}</strong>
            <feather-icon
              class="ml-15"
              :icon="row.item._showDetails === true ? 'ChevronDownIcon' : 'ChevronRightIcon'"
              size="20"
            />
          </a>
        </span>
        <span v-else>
          {{ row.item.date }}
        </span>
      </template>

      <template #row-details="{ item }">
        <div v-if="item">
          <div
            v-if="loading_hourly && !statsDay[item.date]"
            class="text-center"
          >
            <b-spinner label="Loading..." />
          </div>

          <b-table
            v-else-if="!loading_hourly && statsDay[item.date].length"
            small
            :items="statsDay[item.date]"
            :fields="fields"
            responsive
            striped
            thead-class="d-none"
            table-variant="primary"
          >
            <template #cell(date)="row">
              {{ getFormattedDateTimeZone(row.item.date) }}
            </template>
            <template #cell(impressions)="data">
              {{ data.value ? new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(data.value) : 0 }}
            </template>
            <template #cell(clicks)="data">
              {{ data.value ? new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(countObjectTotals(data.value)) : 0 }}
            </template>
            <template #cell(revenue)="data">
              {{ data.value ? currency('USD', countObjectTotals(data.value)) : 0 }}
            </template>
          </b-table>
        </div>
      </template>

      <template #cell(impressions)="data">
        {{ data.value ? new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(data.value) : 0 }}
      </template>

      <template #cell(clicks)="data">
        <span
          class="text-primary cursor-pointer"
          @click="showSidebarDetails(data.item.date, 'clicks')"
        >
          {{ data.value ? new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(countObjectTotals(data.value)) : 0 }}
        </span>
      </template>

      <template #cell(revenue)="data">
        <span
          class="text-primary cursor-pointer"
          @click="showSidebarDetails(data.item.date, 'revenue')"
        >
          {{ currency('USD', countObjectTotals(data.value)) }}
        </span>
      </template>

      <template #foot(date)>
        <strong>{{ $t('Records') }}: {{ rows.length }}</strong>
      </template>

      <template #foot(clicks)>
        <strong
          class="text-primary cursor-pointer"
          @click="showSidebarTotalDetails('clicks')"
        >
          {{ totalFormat('clicks').toLocaleString('Ru-ru') }}
        </strong>
      </template>

      <template #foot(impressions)>
        {{ totalFormat('impressions').toLocaleString('Ru-ru') }}
      </template>

      <template #foot(revenue)>
        <strong
          class="text-primary cursor-pointer"
          @click="showSidebarTotalDetails('revenue')"
        >
          {{ currency('USD', totalFormat('revenue')) }}
        </strong>
      </template>
    </b-table>

    <!-- <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <PartnersStatsSidebar
        ref="sidebar"
        account-currency="USD"
        :sidebar-data="sidebarData"
        :sidebar-data-field="sidebarDataField"
      />
    </b-sidebar>
    <b-sidebar
      id="sidebar-totals-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <PartnersStatsTotalSidebar
        ref="sidebar-totals"
        account-currency="USD"
        :sidebar-data="sidebarTotalData"
        :sidebar-data-field="sidebarDataField"
        :selected-range="selectedRange"
      />
    </b-sidebar> -->
    <b-modal
      id="modal-stats"
      ref="modal-stats"
      centered
      size="lg"
      :title="modalTitle"
      ok-only
      ok-title="Ok"
      scrollable
    >
      <PartnersStatsModal
        account-currency="USD"
        :sidebar-data="sidebarData"
        :sidebar-data-field="sidebarDataField"
      />
    </b-modal>
    <b-modal
      id="modal-stats-totals"
      ref="modal-stats-totals"
      centered
      size="lg"
      :title="modalTitleTotals"
      ok-only
      ok-title="Ok"
      scrollable
    >
      <PartnersStatsTotalModal
        account-currency="USD"
        :selected-range="selectedRangeFormated"
        :sidebar-data="sidebarTotalData"
        :sidebar-data-field="sidebarDataField"
      />
    </b-modal>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import {
  BTable,
  BSpinner,
  // BSidebar,
} from 'bootstrap-vue'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime, getFormattedDateTimeZone } from '@/utils/date-formatter'
// import PartnersStatsSidebar from './PartnersStatsSidebar.vue'
// import PartnersStatsTotalSidebar from './PartnersStatsTotalSidebar.vue'
import PartnersStatsModal from './PartnersStatsModal.vue'
import PartnersStatsTotalModal from './PartnersStatsTotalModal.vue'

export default {
  components: {
    BTable,
    // BSidebar,
    BSpinner,
    // PartnersStatsSidebar,
    // PartnersStatsTotalSidebar,
    PartnersStatsModal,
    PartnersStatsTotalModal,
  },
  props: {
    rows: {
      type: Array,
      deep: true,
      required: true,
      deafult: () => [],
    },
    fields: {
      type: Array,
      required: true,
      deafult: () => [],
    },
    selectedRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading_hourly: true,
      compaignsRows: [],
      campaigns: [],
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      getFormattedDate,
      getFormattedDateTime,
      getFormattedDateTimeZone,
      currency: currencyFormatter,
      rangeDefault: [getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), getFormattedDate(new Date())],
      statsDay: {},
      sidebarData: [],
      statsTotalData: [],
      sidebarTotalData: {},
      IsOpenSidebar: false,
      sidebarDataField: '',
    }
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.ecpm
      })
      return totalAmount
    },
    selectedRangeFormated() {
      let dateFrom
      let dateTo
      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      return {
        from: getFormattedDate(new Date(dateFrom)),
        to: dateTo === undefined ? getFormattedDate(new Date(dateFrom)) : getFormattedDate(new Date(dateTo)),
      }
    },
    modalTitle() {
      let title = ''

      if (this.sidebarData[0] !== undefined) {
        title = `${getFormattedDate(new Date(this.sidebarData[0].date))}`
      }

      return title
    },
    modalTitleTotals() {
      return `${this.$i18n.t('date_range', { from: this.selectedRangeFormated.from, to: this.selectedRangeFormated.to })}`
    },
  },
  created() {
    this.rangePicker = this.rangeDefault
    this.getStatsTotalData()
  },
  methods: {
    async getStatsDay(day) {
      this.loading_hourly = true
      this.statsDay[day] = null
      const params = {
        campaign_id: this.$route.params.id,
        type: 'hourly',
        date: getFormattedDate(new Date(day)),
        ad_type: 'prebid',
      }

      const responseData = await useJwt.getCampaignStatsEcpm(params)
      this.statsDay[day] = responseData.data.items || null
      this.loading_hourly = false
    },
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        if (typeof i[field] === 'object') {
          total += +this.countObjectTotals(i[field])
        } else {
          total += i[field]
        }
      })
      return total
    },
    countObjectTotals(obj) {
      const calc = Object.values(obj).reduce((acc, value) => acc + value, 0)
      return calc.toFixed(2)
    },
    showDetails(row) {
      row.toggleDetails()
      if (row.item._showDetails) {
        if (!this.statsDay[row.item.date]) {
          this.getStatsDay(row.item.date)
        }
      }
    },
    async showSidebarDetails(date, field) {
      this.sidebarData = []
      this.sidebarDataField = field

      const currentItem = this.rows.filter(i => i.date === date)
      if (currentItem.length > 0) {
        this.sidebarData = currentItem
        const ids = Object.keys(currentItem[0][field]).filter(key => key !== 'others')
        if (ids.length > 0) {
          await store.dispatch('dashboard-sites-stats/fetchSiteNamesById', ids)
        }
        // this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
        this.$refs['modal-stats'].show()
      }
    },
    async getStatsTotalData() {
      const { from, to } = this.selectedRange
      this.statsTotalData = []
      const params = {
        campaign_id: this.$route.params.id,
        type: 'daily',
        from,
        to,
        ad_type: 'prebid',
      }

      const responseData = await useJwt.getPartnersCampaignsTotal(params)
      this.statsTotalData = responseData.data.items || []
    },
    async showSidebarTotalDetails(field) {
      this.sidebarTotalData = {}
      this.sidebarDataField = field

      const newItems = this.getRangeTotalsByType(this.statsTotalData, field)
      if (Object.keys(newItems).length > 0) {
        this.sidebarTotalData = newItems
        const ids = Object.keys(newItems).filter(key => key !== 'others')
        if (ids.length > 0) {
          await store.dispatch('dashboard-sites-stats/fetchSiteNamesById', ids)
        }
        // this.$root.$emit('bv::toggle::collapse', 'sidebar-totals-right')
        this.$refs['modal-stats-totals'].show()
      }
    },
    getRangeTotalsByType(data, field) {
      return data.reduce((acc, obj) => {
        Object.entries(obj[field]).forEach(([key, value]) => {
          acc[key] = (acc[key] || 0) + value
        })
        return acc
      }, {})
    },
  },
}
</script>
<style>
[dir] .table-responsive .table-responsive {
  margin-bottom: 0!important;
}

[dir] .table.b-table > tbody > tr.b-table-details > td {
  padding-left: 0!important;
  padding-right: 0!important;
  padding-bottom: 0!important;
  border-bottom: 1px solid #776bf0
}

[dir] .table.b-table > tbody > tr.b-table-details .table-striped tbody tr:nth-of-type(odd) {
  background: #ebebeb;
}

[dir] .table.b-table > tbody td.td-width {
  width: 11%;
}
</style>
<style scoped>
  [dir] .b-table-has-details .btn-flat-primary {
    padding: 0!important;
  }
</style>
